import Cookies from 'js-cookie'

jQuery( document ).ready(function($) {

  console.log(`cookie on load: ${Cookies.get('vote')}`)
  
  $('.option').click(function(e){
    $('.option').removeClass('selected')
    $('.btn-next').removeClass('disabled')
    $(this).addClass('selected')
    Cookies.set('vote', $(this).attr('data-option'))
  })
  

  $('.btn-next').click(function(e){
    e.preventDefault()
    $('.step').removeClass('active')
    $('.step-2').addClass('active')
  })

  // Results
  const setResult = (target, percent) => {
    const option = document.querySelector(`.${target}`)
    option.querySelector(`.number`).innerHTML = percent
    option.querySelector(`.progress`).setAttribute("style",`width:${percent}%`);
  }

  setResult('option-01', 23)
  setResult('option-02', 52)
  setResult('option-03', 25)

  // Spotify
  let SMEPreSave = {
    state: null,
    spotify_user: null,
    url_vars: [],
    getURLVars: function (callback, form_id) {
      let hash;
      let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        this.url_vars.push(hash[0]);
        this.url_vars[hash[0]] = hash[1];
      }
      this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
      this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
      this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
      this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
      this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
      if (typeof callback === 'function') {
        callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
      }
    }
  };
  /* String state\
   *  The status of the pre-save.\
   *  Int ae_member_id\
   *  The Appreciation Engine member ID. This can be passed to the forms processor.\
   *  String spotify_user\
   *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.\
   *  String deezery_user\
   *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.\
   */
  SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
    if (state === 'success' || state === 'thank-you') {
      $('.step').removeClass('active')
      $('.step-thankyou').addClass('active')

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const fanEmail = urlParams.get('email')
      const fanID = urlParams.get('ae_member_id')

      $.ajax({
        url: 'https://subs.sonymusicfans.com/submit',
        method: 'POST',
        data: {
          field_email_address: fanEmail,
          form: '170503',  	
          default_mailing_list: 'a0S1p00000SPDqMEAX',
          ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
          ae_member_id: fanID,
          ae_segment_id: '1486373',
          ae_brand_id: '3604206',
          ae_activities:'{"actions":{"formsubmission":87463},"mailing_list_optins":{"a0S1p00000SPDqMEAX":87363}}',
          'custom_field[Custom_Field_1]': Cookies.get('vote')
        },
        success: function(response) {
          console.log(response);
        },
        error: function() {
          alert('An error has occured!');
        }
      });
    }
  });

  // Apple
  $('.btn-apple').click(function(){
    $('.apple-popup').addClass('active')

    // Wrap your logic in the musickitloaded event
    let AmkPlaylists = [];
    let AmkAlbums = ["1620209610"];
    // Create a new SMEAppleMusic object with your parameters
    let sm = new SMEAppleMusic({
      ae: {
        ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
        brand_id: '3604206',
        segment_id: '1486373',
        activities: '{"actions":{"presave":87362},"mailing_list_optins":{"a0S1p00000SPDqMEAX":87363}}'
      },
      am: {
        dev_token: '',
        save_mode: 'library',
        custom_playlist_name: '',
        resources: {
          playlists: AmkPlaylists,
          albums: AmkAlbums
        }
      },
      sf: {
        form: '170503',
        default_mailing_list: 'a0S1p00000SPDqMEAX'
      },
      smf: {
        campaign_id: '170503',
        campaign_key: 'f7713d91d256e42b511d4f38c5515a6e'
      }
    });
    document.getElementById('apple_music_form').addEventListener('submit', function (e) {
      e.preventDefault();
      // Grab the email address value
      const email = document.getElementById('apple_music_email_address').value;
      // Pass the email address and opt in status
      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
      sm.doActions(email, true).then(function (res) {
        $('.apple-popup, .step').removeClass('active')
        $('.step-thankyou').addClass('active')

        $.ajax({
          url: 'https://subs.sonymusicfans.com/submit',
          method: 'POST',
          data: {
            field_email_address: email,
            form: '170503',  	
            default_mailing_list: 'a0S1p00000SPDqMEAX',
            ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
            ae_member_id: '',
            ae_segment_id: '1486373',
            ae_brand_id: '3604206',
            ae_activities:'{"actions":{"formsubmission":87463},"mailing_list_optins":{"a0S1p00000SPDqMEAX":87363}}',
            'custom_field[Custom_Field_1]': Cookies.get('vote')
          },
          success: function(response) {
            console.log(response);
            console.log('email subscribed');
          },
          error: function() {
            alert('An error has occured!');
          }
        });

      }).catch(function (err) {
        // Error - handle your logic here
      });
    });
  })
  $('.close').click(function(){
    $('.apple-popup').removeClass('active')
  })

});